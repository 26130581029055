import request from '@/utils/request'

// 运营商种类选项数据
export function findCarrierTypeOptions() {
  return [
    { label: '中国移动', value: 'china_mobile' },
    { label: '中国联通', value: 'china_unicom' },
    { label: '中国电信', value: 'china_telecom' },
    { label: '中国广电', value: 'china_broadnet' },
    { label: '融合运营商', value: 'mix_carrier' },
    { label: '国际运营商', value: 'international_carrier' }
  ]
}

// 生命周期选项数据
export function findLifeCycleOptions() {
  return request({
    url: `/life_cycle/options`,
    method: 'get'
  })
}

// 联网状态选项数据
export function findNetStatusOptions() {
  return request({
    url: `/net_status/options`,
    method: 'get'
  })
}

// 流量共享属性选项数据
export function findTrafficSharePropertyOptions() {
  return [
    { label: '单卡', value: 'alone' },
    { label: '前向流量池', value: 'forward' },
    { label: '后向流量池', value: 'backward' }
  ]
}

// 流量属性选项数据
export function findTrafficPropertyOptions() {
  return [
    { label: '通用流量', value: 'general' },
    { label: '定向流量', value: 'directional' }
  ]
}

// 产品tag选项数据
export function findProductTagOptions() {
  return [
    { label: 'NB 套餐', value: 'nb' }
  ]
}

// 流量单位选项数据
export function findTrafficUintOptions() {
  return [
    { label: 'KB', value: 'kb' },
    { label: 'MB', value: 'mb' },
    { label: 'GB', value: 'gb' }
  ]
}

// 用户业务模式选项数据
export function findUserBusinessTypeOptions() {
  return [
    { name: '自定义自然月', value: 'custom_month' },
    { name: '自定义天数', value: 'custom_day' },
    { name: '流量共享', value: 'traffic_share' },
    { name: '流量共享(总池)', value: 'traffic_share_package' },
    { name: '空白卡', value: 'merchant_sales' }
  ]
}

// 套餐类型选项数据
export function findProductTypeOptions() {
  return [
    { name: '通用套餐', value: 'common' },
    { name: '单一运营商流量总包', value: 'traffic_pool_single' },
    { name: '融合运营商流量总包', value: 'traffic_pool_mult' },
    { name: '流量加油包', value: 'addon_flow' },
    { name: '语音加油包', value: 'addon_voice' }
  ]
}

// 批量操作状态选项数据
export function findBatchOperationStatusOptions() {
  return [
    { label: '待处理', value: 'pending' },
    { label: '进行中', value: 'processing' },
    { label: '成功', value: 'success' },
    { label: '失败', value: 'failed' }
  ]
}

// 批量操作类型选项数据
export function findBatchOperationTypeOptions() {
  return request({
    url: `/batch_operation_records/operation_type/options`,
    method: 'get'
  })
}

// 卡片材质选项数据
export function findCardTypeOptions() {
  return request({
    url: `/card_types/options`,
    method: 'get'
  })
}

// 卡片材质选项数据
export function findCardShapeOptions(cardTypeId) {
  return request({
    url: `/card_types/${cardTypeId}/card_shape/options`,
    method: 'get'
  })
}

// 全部供应商账户选项数据
export function findAllSupplierAccountOptions() {
  return request({
    url: `/supplier_accounts/all/options`,
    method: 'get'
  })
}

// 运营商账户选项数据
export function findCarrierAccountOptions(carrierType) {
  return request({
    url: `/carrier_accounts/options`,
    method: 'get',
    params: { carrier_type: carrierType }
  })
}

// 全部运营商账户选项数据
export function findAllCarrierAccountOptions() {
  return request({
    url: `/carrier_accounts/all/options`,
    method: 'get'
  })
}

// 运营商状态选项数据
export function findCarrierAccountCardStatusOptions(carrierAccountId) {
  return request({
    url: `/carrier_accounts/${carrierAccountId}/card_status/options`,
    method: 'get'
  })
}

// 可采购的运营商套餐选项数据
export function findPurchasableCarrierAccountProductOptions(carrierAccountId) {
  return request({
    url: `/carrier_products/purchasable/options`,
    method: 'get',
    params: { carrier_account_id: carrierAccountId }
  })
}

// 套餐可用加油包选项数据
export function findAddonProductOptions(agentId) {
  return request({
    url: `/agents_products/addon_product/options`,
    method: 'get',
    params: { agent_id: agentId }
  })
}

// 短信接口类别
export function findSmsChannelTypeOptions() {
  return [
    { label: '数据库', value: 'database' },
    { label: 'API', value: 'api' }
  ]
}

// 销卡类型选项数据
export function findCancelCardTypeOptions() {
  return [
    { label: '服务周期到期', value: 'service_end' },
    { label: '提请销卡', value: 'request_cancel' }
  ]
}

// 运营商接口事件名称类别
export function findSimCardCarrierTaskStatusOptions() {
  return [
    { label: '待处理', value: 'pending' },
    { label: '排队中', value: 'queueing' },
    { label: '处理中', value: 'processing' },
    { label: '成功', value: 'success' },
    { label: '失败', value: 'fail' }
  ]
}

// vpdns选项数据
export function findVpdnOptions(agentId) {
  return request({
    url: `/vpdns/options`,
    method: 'get',
    params: { agent_id: agentId }
  })
}

// 售后问题类型选项数据
export function findCustomerServiceIssueOptions() {
  return request({
    url: `/customer_service/issue_types_options`,
    method: 'get'
  })
}
